<template>

  <div>
    <b-overlay
      :show="customers.isPending"
      bg-color="#ffffff"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="lg"
    >
      <customer-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        @refetch-data="refetchData"
      />
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>

            <!-- Search & Add user -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  @keyup.enter="refetchData"
                />
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="refetchData"
                >
                  <span class="text-nowrap">Search</span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add Customer</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchCustomers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-secondary"
                class="mt-0"
                size="sm"
                :to="{ name: 'customer-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span>Edit</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0"
                size="sm"
                @click="deleteCustomerModal(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span>Delete</span>
              </b-button>
            </b-button-group>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="dataMeta.of"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
      <b-modal
        v-model="modalShow"
        title="Delete customer"
        class="modal modal-danger"
        ok-title="Confirm"
        @ok="deleteCustomer(customer.id)"
      >
        <span>Confirm if you want to delete the customer:</span>
        <b-row class="customer-form">
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label="name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="customer.name"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label="Last name"
              label-for="last-name"
            >
              <b-form-input
                id="last-name"
                v-model="customer.lastName"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label="phone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="customer.phone"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              label="email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="customer.email"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BButton, BButtonGroup,
  BPagination, BFormInput, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import customerStoreModule from './StylistCustomerStoreModule'
import useStylistCustomer from './useStylistCustomer'
import CustomerListAddNew from './StylistCustomerListAddNew.vue'

export default {
  components: {
    CustomerListAddNew,

    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BOverlay,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalShow: false,
      customer: {
        id: null,
        name: null,
        lastName: null,
        email: null,
        phone: null,
      },
    }
  },
  computed: {
    ...mapState('StylistCustomerStoreModule', {
      customers: state => state.customers,
    }),
  },
  methods: {
    deleteCustomerModal(customer) {
      this.modalShow = !this.modalShow
      this.customer = { ...customer }
    },
  },
  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchCustomers,
      deleteCustomer,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
    } = useStylistCustomer()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchCustomers,
      deleteCustomer,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.customer-form {
  padding-top: 1.5rem;
}

.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
